export default {
	//生成随机数
	randomNumber:(min, max)=> {
		return Math.floor(Math.random() * (max - min)) + min;
	},
	//数组排序
	sortarr:(a,b)=>{
		return b-a;
	},
	//对象属性排序
	sortObject:(a, b)=> {
		if (a.values < b.values) {
			return 1;
		} else if (a.values > b.values) {
			return -1;
		} else if (a.values == b.values) {
			return 0;
		}
	}
}