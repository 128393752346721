import request from '../utils/request'
export const xh_emotion_list=()=>{
    return request({
        url:'/admin/xh_emotion_list',
        method:'post',
        data:{}
    })
}
export const xh_emotion_detial=(imei)=>{
    return request({
        url:'/admin/xh_emotion_detial',
        method:'post',
        data:{
			imei
		}
    })
}
export const emotion_list=(company,name,job,branch1,branch2,date,pagenum,pagesize,enddate)=>{
    return request({
        url:'/admin/emotion_list',
        method:'post',
        data:{
            company,
            name,
            job,
            branch1,
            branch2,
            date,
            pagenum,
            pagesize,
			enddate
        }
    })
}
export const up_wordimg=(data)=>{
    return request({
        url:'/admin/up_wordimg',
        method:'post',
        data
    })
}
export const get_group_word=(data)=>{
    return request({
        url:'/admin/get_group_word',
        method:'post',
        data
    })
}
export const up_wordimg_group=(data)=>{
    return request({
        url:'/admin/up_wordimg_group',
        method:'post',
        data
    })
}
export const person_word=(data)=>{
    return request({
        url:'/admin/person_word',
        method:'post',
        data
    })
}
export const person_day=(imei,date)=>{
    return request({
        url:'/admin/person_day',
        method:'post',
        data:{
            imei,
            date
        }
    })
}
export const person_min=(imei,date)=>{
    return request({
        url:'/admin/person_min',
        method:'post',
        data:{
            imei,
            date
        }
    })
}
export const person_all_day=(imei,start_date,end_date)=>{
    return request({
        url:'/admin/person_all_day',
        method:'post',
        data:{
            imei,
            start_date,
            end_date
        }
    })
}
