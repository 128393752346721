<template>
	<div class="container">
		<el-breadcrumb separator="/">
			<el-breadcrumb-item>管理后台</el-breadcrumb-item>
			<el-breadcrumb-item>情绪识别</el-breadcrumb-item>
			<el-breadcrumb-item>监测记录</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-row>
				<el-col :span="24">
					<el-form :inline="true">
						<!-- 						<el-form-item>
							<el-input size="small" placeholder="大修编号" v-model="searchNumber"></el-input>
						</el-form-item> -->
						<el-form-item>
							<el-input size="small" placeholder="姓名" v-model="searchName"></el-input>
						</el-form-item>
						<el-form-item>
							<el-input size="small" placeholder="职务" v-model="searchMajor"></el-input>
						</el-form-item>
						<el-form-item>
							<el-select v-model="searchDepartment1" size="small" placeholder="一级部门"
								:disabled="branchArr[0] ? true:false" @change="choiceA">
								<el-option v-for="item in department1List" :key="item.branch" :label="item.branch"
									:value="item.branch">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-select v-model="searchDepartment2" v-if="department2List.length>0" size="small"
								placeholder="班组" :disabled="branchArr[1] ? true:false">
								<el-option v-for="item in department2List" :key="item.branch" :label="item.branch"
									:value="item.branch">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-date-picker v-model="emotionDate" type="datetime" size="small" placeholder="选择开始时间"
							value-format="timestamp" :picker-options="pickerOptions">
							</el-date-picker>
						</el-form-item>
						<el-form-item>
							<el-date-picker v-model="emotionendDate" type="datetime" size="small" placeholder="选择截止时间"
								value-format="timestamp" :picker-options="pickerOptions">
							</el-date-picker>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" size="small" @click="search">查询</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" size="small" @click="reset">重置</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="success" size="small" v-if="personnelData.length>0"
								@click="exportDataEcexl">
								导出数据
							</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" size="small" @click="showhz">
								查看汇总数据
							</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" size="small" @click="exportWordGroupWord()">
								导出团体报告
							</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<el-table id="out-table" :data="personnelData" border stripe :fit="true" style="width: 100%">
				<!-- 				<el-table-column :show-overflow-tooltip="true" width="80px" align="center" prop="serial_number"
					label="大修编号">
				</el-table-column> -->
				<el-table-column :show-overflow-tooltip="true" width="80px" align="center" prop="nickname" label="姓名">
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" align="center" prop="job" label="职务">
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" align="center" min-width="100px" prop="branch_1"
					:fit="true" label="一级部门">
				</el-table-column>
				<!-- 				<el-table-column :show-overflow-tooltip="true" width="56px" align="center" prop="branch_2" label="班组">
					<template slot-scope="scope">
						<div v-if="scope.row.branch_2!='undefined'&&scope.row.branch_2!='null'">{{scope.row.branch_2}}
						</div>
						<div v-else></div>
					</template>
				</el-table-column> -->
				<el-table-column :show-overflow-tooltip="true" align="center" prop="emotion_mean" label="心情指数">
					<template slot-scope="scope">
						<div>{{formatEmotion(scope.row.emotion_mean)}}</div>
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" align="center" prop="tired_mean" label="疲劳指数">
					<template slot-scope="scope">
						<div>{{formatTired(scope.row.tired_mean)}}</div>
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" align="center" prop="stress_mean" label="压力指数">
					<template slot-scope="scope">
						<div>{{formatStress(scope.row.stress_mean)}}</div>
					</template>
					<!--<template slot-scope="scope">
						<div>{{formatStress(scope.row.stress_mean)}}</div>
					</template> -->
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" width="78px" align="center" prop="pedometer"
					label="步数(步)">
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" align="center" prop="hr_mean" label="心率(bmp)">
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" min-width="100px" align="center" prop="pressure"
					label="血压(mmHg)">
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" align="center" prop="oxygen" label="血氧(%)">
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" width="78px" align="center" prop="temperature"
					label="体温(°C)">
				</el-table-column>
				<el-table-column label="操作" align="center" width="328">
					<template slot-scope="scope">
						<el-row>
						<el-button size="mini" type="primary" @click="preview(scope.row)">查看概览</el-button>
						<el-button size="mini" type="primary" @click="exportWord(scope.row,'msword')">导出word报告</el-button>
						<el-button size="mini" type="primary" @click="exportWord(scope.row,'pdf')">导出pdf报告</el-button>
						<!-- <el-button size="mini" type="primary" @click="preview(scope.row)">导出PDF报告</el-button> -->
						</el-row>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagenum"
				:page-sizes="[5,10,15,20]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</el-card>
		<el-dialog title="概览" :visible.sync="previewVisible" width="1200px">
			<div class="serach-box">
				<el-date-picker size="small" v-model="searchDate" type="daterange" unlink-panels
					format="yyyy 年 MM 月 dd 日" value-format="timestamp" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
				<el-button type="primary" size="small" @click="searchPreview">查询</el-button>
			</div>
			<div class="chart-box">
				<div id="preview-chart"></div>
			</div>
			<div class="pie-list">
				<div class="pie-box">
					<div class="pie-title">心情指数</div>
					<div class="pie-main">
						<div id="emotion-chart"></div>
						<div class="percentage-list">
							<div class="percentage-item">
								<div class="item-name">
									<div class="item-icn color-c"></div>
									<div class="name-text">有点差</div>
								</div>
								<div class="interval">0-49</div>
								<div class="percentage-num">{{emotionPercentNum[0]}}%</div>
							</div>
							<div class="percentage-item">
								<div class="item-name">
									<div class="item-icn color-b"></div>
									<div class="name-text">心情平和</div>
								</div>
								<div class="interval">50-69</div>
								<div class="percentage-num">{{emotionPercentNum[1]}}%</div>
							</div>
							<div class="percentage-item">
								<div class="item-name">
									<div class="item-icn color-a"></div>
									<div class="name-text">比较愉悦</div>
								</div>
								<div class="interval">70-100</div>
								<div class="percentage-num">{{emotionPercentNum[2]}}%</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pie-box">
					<div class="pie-title">疲劳指数成分比例</div>
					<div class="pie-main">
						<div id="tired-chart"></div>
						<div class="percentage-list">

							<div class="percentage-item">
								<div class="item-name">
									<div class="item-icn color-c    "></div>
									<div class="name-text">感到劳累</div>

								</div>
								<div class="interval">70-100</div>
								<div class="percentage-num">{{tiredPercentNum[2]}}%</div>
							</div>
							<div class="percentage-item">
								<div class="item-name">
									<div class="item-icn color-b"></div>
									<div class="name-text">略有疲惫</div>
								</div>
								<div class="interval">50-69</div>
								<div class="percentage-num">{{tiredPercentNum[1]}}%</div>
							</div>

							<div class="percentage-item">
								<div class="item-name">
									<div class="item-icn color-a"></div>
									<div class="name-text">精力充沛</div>
								</div>
								<div class="interval">0-49</div>
								<div class="percentage-num">{{tiredPercentNum[0]}}%</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pie-box">
					<div class="pie-title">压力指数成分比例</div>
					<div class="pie-main">
						<div id="stress-chart"></div>
						<div class="percentage-list">
							<div class="percentage-item">
								<div class="item-name">
									<div class="item-icn color-c"></div>
									<div class="name-text">压力较大</div>
								</div>
								<div class="interval">70-100</div>
								<div class="percentage-num">{{stressPercentNum[2]}}%</div>
							</div>
							<div class="percentage-item">
								<div class="item-name">
									<div class="item-icn color-b"></div>
									<div class="name-text">稍有压力</div>
								</div>
								<div class="interval">50-69</div>
								<div class="percentage-num">{{stressPercentNum[1]}}%</div>
							</div>
							<div class="percentage-item">
								<div class="item-name">
									<div class="item-icn color-a"></div>
									<div class="name-text">较为松弛</div>
								</div>
								<div class="interval">0-49</div>
								<div class="percentage-num">{{stressPercentNum[0]}}%</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="info" @click="closePreview">关闭</el-button>
				<el-button type="success" v-if="minChartData.length != 0 || emotionAll.length != 0"
					@click="exportminData">
					导出数据
				</el-button>
			</span>
		</el-dialog>
		<el-dialog title="查看汇总数据" :visible.sync="hzpreviewVisible" width="1200px">
			<el-form :inline="true">
				<el-form-item>
					<el-select collapse-tags multiple v-model="abranchv1" size="small" placeholder="一级部门"
						@change="abranchA()">
						<el-option v-for="(item,index) in abranch1" :key="index" :label="item.branch"
							:value="item.branch">
						</el-option>
					</el-select>
				</el-form-item>
				<!-- 				<el-form-item>
					<el-select collapse-tags multiple v-model="abranchv2" size="small" placeholder="班组"
						@change="abranchB()">
						<el-option v-for="(item,index) in abranchChange2" :key="index" :label="item.branch"
							:value="item.branch">
						</el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item>
					<el-select collapse-tags multiple v-model="abranchv3" size="small" placeholder="人员">
						<el-option v-for="(item,index) in persionShow" :key="index"
							:label="item.nickname+'-'+item.phone.slice(-4)" :value="item.imei">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<!-- 					<el-date-picker size="small" v-model="hzsearchDate" type="daterange" unlink-panels
						format="yyyy 年 MM 月 dd 日" value-format="timestamp" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期">
					</el-date-picker> -->
					<el-date-picker size="small" v-model="hzsearchDate" type="daterange" unlink-panels
						format="yyyy 年 MM 月 dd 日" value-format="timestamp" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="small" @click="searchHz">查询</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="small" @click="showhz('reset')">重置</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" v-if="hzdata.length>0" size="small" @click="exportsHzdata">导出汇总数据
					</el-button>
				</el-form-item>
			</el-form>
			<div class="chart-box">
				<div id="hzpreview-chart" style="width: 100%;height: 100%;"></div>
			</div>
			<div class="pie-list">
				<div class="pie-box">
					<div class="pie-title">心情指数成分比例</div>
					<div class="pie-main">
						<div id="emotion-hzchart"></div>
						<div class="percentage-list">
							<div class="percentage-item">
								<div class="item-name">
									<div class="item-icn color-a"></div>
									<div class="name-text">比较愉悦</div>
								</div>
								<div class="interval">70-100</div>
								<div class="percentage-num">{{emotion_hzchartList[0]}}%</div>
							</div>
							<div class="percentage-item">
								<div class="item-name">
									<div class="item-icn color-b color-bc"></div>
									<div class="name-text">心情平和</div>
								</div>
								<div class="interval">50-69</div>
								<div class="percentage-num">{{emotion_hzchartList[1]}}%</div>
							</div>

							<div class="percentage-item">
								<div class="item-name">
									<div class="item-icn color-c"></div>
									<div class="name-text">有点差</div>
								</div>
								<div class="interval">0-49</div>
								<div class="percentage-num">{{emotion_hzchartList[2]}}%</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pie-box">
					<div class="pie-title">疲劳指数成分比例</div>
					<div class="pie-main">
						<div id="tired-hzchart"></div>
						<div class="percentage-list">
							<div class="percentage-item">
								<div class="item-name">
									<div class="item-icn color-a"></div>
									<div class="name-text">精力充沛</div>
								</div>
								<div class="interval">70-100</div>
								<div class="percentage-num">{{tired_hzchartList[0]}}%</div>
							</div>
							<div class="percentage-item">
								<div class="item-name">
									<div class="item-icn color-b color-bc"></div>
									<div class="name-text">略有疲惫</div>
								</div>
								<div class="interval">50-69</div>
								<div class="percentage-num">{{tired_hzchartList[1]}}%</div>
							</div>
							<div class="percentage-item">
								<div class="item-name">
									<div class="item-icn color-c    "></div>
									<div class="name-text">感到劳累</div>
								</div>
								<div class="interval">0-49</div>
								<div class="percentage-num">{{tired_hzchartList[2]}}%</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pie-box">
					<div class="pie-title">压力指数成分比例</div>
					<div class="pie-main">
						<div id="stress-hzchart"></div>
						<div class="percentage-list">
							<div class="percentage-item">
								<div class="item-name">
									<div class="item-icn color-a"></div>
									<div class="name-text">压力较大</div>
								</div>
								<div class="interval">70-100</div>
								<div class="percentage-num">{{stress_hzchartList[0]}}%</div>
							</div>
							<div class="percentage-item">
								<div class="item-name">
									<div class="item-icn color-b color-bc"></div>
									<div class="name-text">稍有压力</div>
								</div>
								<div class="interval">50-69</div>
								<div class="percentage-num">{{stress_hzchartList[1]}}%</div>
							</div>
							<div class="percentage-item">
								<div class="item-name">
									<div class="item-icn color-c"></div>
									<div class="name-text">较为松弛</div>
								</div>
								<div class="interval">0-49</div>
								<div class="percentage-num">{{stress_hzchartList[2]}}%</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="info" @click="hzpreviewVisible = false">关闭</el-button>
			</span>
		</el-dialog>
		<div>
			<div style="display: flex;align-items: center;flex-direction: column;position: absolute;top: -4500px;">
				<!-- <img width="475" height="360" :src="curl"/> -->
				<div id="groupE" style="width: 560px;height: 375px;"></div>
				<div id="groupEs" style="width: 560px;height: 375px;"></div>
				<div id="groupT" style="width: 560px;height: 375px;"></div>
				<div id="groupTs" style="width: 560px;height: 375px;"></div>
				<div id="groupS" style="width: 560px;height: 375px;"></div>
				<div id="groupSs" style="width: 560px;height: 375px;"></div>
				<div id="groupA" style="width: 560px;height: 375px;"></div>
				<div id="groupAs" style="width: 560px;height: 375px;"></div>
				<div id="wordviewRRI" style="width: 560px;height: 375px;"></div>
				<div id="wordviewE" style="width: 560px;height: 375px;"></div>
				<div id="wordviewT" style="width: 560px;height: 375px;"></div>
				<div id="wordviewS" style="width: 560px;height: 375px;"></div>

			</div>
		</div>
	</div>
</template>
<script>
	import moment from "moment";
	import XLSX from "xlsx";
	import util from "../utils/utils.js"
	import {
		getAllbarnchPersion,
		personAlldata,
		getAllbarnch,
		department_list
	} from '../api/department'
	import {
		emotion_list,
		person_day,
		person_word,
		up_wordimg,
		up_wordimg_group,
		get_group_word,
		person_min,
		person_all_day
	} from '../api/monitoring'
	// import {emotion_list} from '../api/emotion'
	import {
		get_branch
	} from '../api/permissions.js'
	import server_url from '../utils/baseUrl'
	import {
		bian_log
	} from '../api/log'
	var markLine = {
		symbol: '',
		data: [{
				yAxis: 0,
				label: {
					formatter: '',
					position: 'start',
					color: 'red',
					fontSize: 15
				},
				lineStyle: {
					color: 'red'
				}
			}, {
				yAxis: 25,
				label: {
					formatter: '',
					position: 'start',
					color: 'red',
				},
				lineStyle: {
					color: 'red'
				}
			},
			{
				yAxis: 50,
				label: {
					formatter: '  50',
					position: 'start',
					color: '#FBDB0F',
					fontSize: 15
				},
				lineStyle: {
					color: '#FBDB0F'
				}
			},
			{
				yAxis: 100,
				label: {
					formatter: '  100',
					position: 'start',
					color: '#05fb00',
					fontSize: 15
				},
				lineStyle: {
					color: '#05fb00',
					type: 'solid'
				}
			}
		]
	}

	function sortObject(a, b) {
		if (a.val < b.val) {
			return 1;
		} else if (a.val > b.val) {
			return -1;
		} else if (a.val == b.val) {
			return 0;
		}
	}

	function standardDeviation(arr) {
		let avg = 0;
		let length = arr.length;
		let len = arr.length;
		let sum = 0;
		for (let i = 0; i < len; i++) {
			sum += arr[i];
		}
		avg = sum / len;
		let temp = [];
		for (let i = 0; i < length; i++) {
			let dev = (arr[i]) - avg; //计算数组元素与平均值的差
			temp[i] = Math.pow(dev, 2); //计算差的平方
		}
		let powSum = 0; //用来存储差的平方总和
		for (let j = 0; j < temp.length; j++) {
			if (temp[j]) {
				powSum += temp[j]; //计算差的平方总和
			}
		}
		return parseFloat(Math.sqrt(powSum / length).toFixed(2));
	}
	export default {
		data() {
			return {
				wordviewVisible: false,
				persionList: [],
				persionShow: [],
				persionSelect: [],
				abranch1: [],
				abranch2: [],
				abranchChange2: [],
				abranch3: [],
				abranchv1: [],
				abranchv2: [],
				abranchv3: [],
				abranchDate: '',
				rowData: null,
				minChartData: [],
				minChartTime: [],
				company: '',
				companyData: [],
				pagenum: 1,
				pagesize: 10,
				total: 0,
				searchName: '',
				searchMajor: '',
				emotionDate: '',
				emotionendDate: '',
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > new Date().getTime();
					}
				},
				branchArr: [],
				department1List: [],
				department2List: [],
				searchDepartment1: null,
				searchDepartment2: null,
				personnelData: [],
				personnelLink: '',
				previewVisible: false,
				hzpreviewVisible: false,
				emotionAllHour: [],
				tiredAllHour: [],
				stressAllHour: [],
				preview_chart: '',
				publicUrl: server_url,
				personnelDayLink: '',
				previewImei: '',
				searchDate: '',
				searchDatePersion: '',
				hzsearchDate: '',
				emotionAll: [],
				stressAll: [],
				tiredAll: [],
				dateAll: [],
				emotionDom: '',
				tiredDom: '',
				stressDom: '',
				percentEmotion: [],
				percentStress: [],
				percentTired: [],
				emotionPercentNum: [],
				stressPercentNum: [],
				tiredPercentNum: [],
				options: [],
				branch_1: [],
				branch_1_str: '',
				branch_2: [],
				branch_2_str: '',
				branch_company: [],
				branch_company_str: '',
				imeiList: [],
				preview_chart_hz: '',
				emotion_hzchart: '',
				tired_hzchart: '',
				stress_hzchart: '',
				emotion_hzchartList: '',
				tired_hzchartList: '',
				stress_hzchartList: '',
				hzdata: [],
				alldata: [],
				curl: ''
			}
		},
		computed: {},
		methods: {
			exportWordGroupWord() {
				let women = 0;
				let daytime = [this.emotionDate, this.emotionendDate];
				this.alldata.forEach(item => {
					if (item.gender == '女') {
						women += 1;
					}
				})
				const loading = this.$loading({
					lock: true,
					text: '文档正在生成中，请稍等',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
				});
				get_group_word({
					list: this.alldata,
					daytime,
					emotionDate: this.emotionDate,
					emotionendDate: this.emotionendDate
				}).then(res => {
					let list = res.list;
					let persion = {
						women: 0,
						man: 0
					}
					//情绪中高低占比最多人员数量
					let highestEmo = {
						ehig: 0,
						emid: 0,
						elow: 0,
						ehigList: [],
						elowList: [],
						thig: 0,
						tmid: 0,
						tlow: 0,
						thigList: [],
						tlowList: [],
						shig: 0,
						smid: 0,
						slow: 0,
						shigList: [],
						slowList: [],
						ahig: 0,
						amid: 0,
						alow: 0,
						ahigList: [],
						alowList: []
					}
					for (let i in list) {
						let emotionA = 0,
							emotionB = 0,
							emotionC = 0;
						let tiredA = 0,
							tiredB = 0,
							tiredC = 0;
						let stressA = 0,
							stressB = 0,
							stressC = 0;
						let stableA = 0,
							stableB = 0,
							stableC = 0;
						let arr = list[i].hourdata;
						if (list.gender == '女') {
							persion.women += 1;
						}
						for (let i in arr) {
							let item = arr[i]
							if (item.emotion_mean >= 0 && item.emotion_mean <= 49) {
								emotionA += 1
							}
							if (item.emotion_mean >= 50 && item.emotion_mean < 70) {
								emotionB += 1
							}
							if (item.emotion_mean >= 70 && item.emotion_mean <= 100) {
								emotionC += 1
							}
							//tired_mean
							if (item.tired_mean >= 0 && item.tired_mean <= 49) {
								tiredA += 1
							}
							if (item.tired_mean >= 50 && item.tired_mean < 70) {
								tiredB += 1
							}
							if (item.tired_mean >= 70 && item.tired_mean <= 100) {
								tiredC += 1
							}
							//stress_mean
							if (item.stress_mean >= 0 && item.stress_mean <= 49) {
								stressA += 1
							}
							if (item.stress_mean >= 50 && item.stress_mean < 70) {
								stressB += 1
							}
							if (item.stress_mean >= 70 && item.stress_mean <= 100) {
								stressC += 1
							}
							if (item.emoStable >= 30) {
								stableC+=1;
							}
							if (item.emoStable >= 15 && item.emoStable < 30) {
								stableB+=1;
							}
							if (item.emoStable <15) {
								stableA+=1;
							}
						}


						list[i].emotionA = emotionA;
						list[i].emotionB = emotionB;
						list[i].emotionC = emotionC;

						list[i].tiredA = tiredA;
						list[i].tiredB = tiredB;
						list[i].tiredC = tiredC;

						list[i].stressA = stressA;
						list[i].stressB = stressB;
						list[i].stressC = stressC;
						
						list[i].stableA = stableA;
						list[i].stableB = stableB;
						list[i].stableC = stableC;

						// list[i].emotionPre=[{val:emotionA,name:'hig'},{val:emotionB,name:'min'},{val:emotionC,name:'low'}];
						let emotionPre = [{
							val: emotionA,
							name: 'low'
						}, {
							val: emotionB,
							name: 'mid'
						}, {
							val: emotionC,
							name: 'hig'
						}];
						let higemo = emotionPre.sort(sortObject)
						//占比最高的情绪
						list[i].emotionPre = {
							higpre: (higemo[0].val / arr.length).toFixed(2),
							higemo
						}

						let tiredPre = [{
							val: tiredA,
							name: 'low'
						}, {
							val: tiredB,
							name: 'mid'
						}, {
							val: tiredC,
							name: 'hig'
						}];
						let higtir = tiredPre.sort(sortObject)
						//占比最高的疲劳
						list[i].tiredPre = {
							higpre: (higtir[0].val / arr.length).toFixed(2),
							higtir
						}

						let stressPre = [{
							val: stressA,
							name: 'low'
						}, {
							val: stressB,
							name: 'mid'
						}, {
							val: stressC,
							name: 'hig'
						}];
						let higstr = stressPre.sort(sortObject)
						//占比最高的压力
						list[i].stressPre = {
							higpre: (higstr[0].val / arr.length).toFixed(2),
							higstr
						}
						
						let stablePre = [{
							val: stableA,
							name: 'low'
						}, {
							val: stableB,
							name: 'mid'
						}, {
							val: stableC,
							name: 'hig'
						}];
						let higsta = stablePre.sort(sortObject)
						//占比最高的情绪
						list[i].stablePre = {
							higpre: (higsta[0].val / arr.length).toFixed(2),
							higsta
						}

						if (higemo[0].name == 'hig') {
							highestEmo.ehig += 1;
							highestEmo.ehigList.push(list[i].nickname);
						}
						if (higemo[0].name == 'mid') {
							highestEmo.emid += 1;
						}
						if (higemo[0].name == 'low') {
							highestEmo.elow += 1;
							highestEmo.elowList.push(list[i].nickname);
						}

						if (higtir[0].name == 'hig') {
							highestEmo.thig += 1;
							highestEmo.thigList.push(list[i].nickname);
						}
						if (higtir[0].name == 'mid') {
							highestEmo.tmid += 1;
						}
						if (higtir[0].name == 'low') {
							highestEmo.tlow += 1;
							highestEmo.tlowList.push(list[i].nickname);
						}

						if (higstr[0].name == 'hig') {
							highestEmo.shig += 1;
							highestEmo.shigList.push(list[i].nickname);
						}
						if (higstr[0].name == 'mid') {
							highestEmo.smid += 1;
						}
						if (higstr[0].name == 'low') {
							highestEmo.slow += 1;
							highestEmo.slowList.push(list[i].nickname);
						}
						
						if (higsta[0].name == 'hig') {
							highestEmo.ahig += 1;
							highestEmo.ahigList.push(list[i].nickname);
						}
						if (higsta[0].name == 'mid') {
							highestEmo.amid += 1;
						}
						if (higsta[0].name == 'low') {
							highestEmo.alow += 1;
							highestEmo.alowList.push(list[i].nickname);
						}
						// list[i].emotionB=emotionB;
						// list[i].emotionC=emotionC;

					}
					persion.all = list.length;
					persion.man = list.length - persion.women;
					//图表渲染
					let wordcE = this.$echarts.init(document.getElementById('groupE'));
					wordcE.setOption(this.getPieOpt('情绪', highestEmo));
					
					let wordcT = this.$echarts.init(document.getElementById('groupT'));
					wordcT.setOption(this.getPieOpt('疲劳', highestEmo));
					
					let wordcS = this.$echarts.init(document.getElementById('groupS'));
					wordcS.setOption(this.getPieOpt('压力', highestEmo));
					
					let wordcA = this.$echarts.init(document.getElementById('groupA'));
					wordcA.setOption(this.getPieOpt('情绪稳定性', highestEmo));
					//全时段的好中差的人数
					let allEmotionList = [
						[],
						[],
						[]
					]
					let allTiredList = [
						[],
						[],
						[]
					]
					let allStressList = [
						[],
						[],
						[]
					]
					let allStableList = [
						[],
						[],
						[]
					]
					let allhour = res.alldata;
					let allhourStr = [];
					//某小时某种情绪成分人数比例>50%
					let higEmotionStr = [];
					let higTiredStr = [];
					let higStressStr = [];
					let higStableStr = [];
					let midEmotionStr = [];
					let midTiredStr = [];
					let midStressStr = [];
					let midStableStr = [];
					let lowEmotionStr = [];
					let lowTiredStr = [];
					let lowStressStr = [];
					let lowStableStr = [];
					for (let i in allhour) {
						if (allhour[i].length) {
							allhourStr.push(allhour[i][0].time)
							// allhourNum.push(allhour[i].length)
							// let item = allhour[i];
							let ea=0,eb=0,ec=0;
							let ta=0,tb=0,tc=0;
							let sa=0,sb=0,sc=0;
							let sta=0,stb=0,stc=0;
							allhour[i].forEach(item =>{
								if (item.emotion_mean >= 0 && item.emotion_mean <= 49) {
									ea+=1;
								}
								if (item.emotion_mean >= 50 && item.emotion_mean < 70) {
									eb+=1;
								}
								if (item.emotion_mean >= 70 && item.emotion_mean <= 100) {
									ec+=1;
								}
								if (item.tired_mean >= 0 && item.tired_mean <= 49) {
									ta+=1;
								}
								if (item.tired_mean >= 50 && item.tired_mean < 70) {
									tb+=1;
								}
								if (item.tired_mean >= 70 && item.tired_mean <= 100) {
									tc+=1;
								}
								if (item.stress_mean >= 0 && item.stress_mean <= 49) {
									sa+=1;
								}
								if (item.stress_mean >= 50 && item.stress_mean < 70) {
									sb+=1;
								}
								if (item.stress_mean >= 70 && item.stress_mean <= 100) {
									sc+=1;
								}
								
								if (item.emoStable >= 30) {
									stc+=1;
								}
								if (item.emoStable >= 15 && item.emoStable < 30) {
									stb+=1;
								}
								if (item.emoStable <15) {
									sta+=1;
								}
							})
							allEmotionList[0].push(ec)
							allEmotionList[1].push(eb)
							allEmotionList[2].push(ea)
							allTiredList[0].push(tc)
							allTiredList[1].push(tb)
							allTiredList[2].push(ta)
							allStressList[0].push(sc)
							allStressList[1].push(sb)
							allStressList[2].push(sa)
							allStableList[0].push(stc)
							allStableList[1].push(stb)
							allStableList[2].push(sta)
							if(ec/(ec+eb+ea)>0.5){
								higEmotionStr.push(allhour[i][0].time)
							}else if(eb/(ec+eb+ea)>0.5){
								midEmotionStr.push(allhour[i][0].time)
							}else if(ea/(ec+eb+ea)>0.5){
								lowEmotionStr.push(allhour[i][0].time)
							}
							if(tc/(tc+tb+ta)>0.5){
								higTiredStr.push(allhour[i][0].time)
							}else if(tb/(tc+tb+ta)>0.5){
								midTiredStr.push(allhour[i][0].time)
							}else if(ta/(tc+tb+ta)>0.5){
								lowTiredStr.push(allhour[i][0].time)
							}
							if(stc/(stc+stb+sta)>0.5){
								higStableStr.push(allhour[i][0].time)
							}else if(stb/(stc+stb+sta)>0.5){
								midStableStr.push(allhour[i][0].time)
							}else if(sta/(stc+stb+sta)>0.5){
								lowStableStr.push(allhour[i][0].time)
							}
							if(sc/(sc+sb+sa)>0.5){
								higStressStr.push(allhour[i][0].time)
							}else if(sb/(sc+sb+sa)>0.5){
								midStressStr.push(allhour[i][0].time)
							}else if(sb/(sc+sb+sa)>0.5){
								midStressStr.push(allhour[i][0].time)
							}
						}
					}
					let domEs = document.getElementById('groupEs')
					let wordcEs = this.$echarts.init(domEs);
					wordcEs.setOption({
						animation: false,
						title: {
							text: '情绪状况走势图',
							left: 'center'
						},
						legend: {
							left: 'center',
							bottom: 10,
						},
						xAxis: {
							type: 'category',
							data: allhourStr,
						},
						yAxis: {
							type: 'value',
							minInterval: 1
						},
						series: [{
							name: '情绪较好人数',
							data: allEmotionList[0],
							type: 'bar',
							itemStyle:{
								color:'#4472c4'
							}
						},{
							name: '情绪中等人数',
							data: allEmotionList[1],
							type: 'bar',
							itemStyle:{
								color:'#ed7d31'
							}
						},{
							name: '低于一般人数',
							data: allEmotionList[2],
							type: 'bar',
							itemStyle:{
								color:'#a5a5a5'
							}
						}]
					});
					let domTs = document.getElementById('groupTs')
					let wordcTs = this.$echarts.init(domTs);
					wordcTs.setOption({
						animation: false,
						title: {
							text: '疲劳状况走势图',
							left: 'center'
						},
						legend: {
							left: 'center',
							bottom: 10,
						},
						xAxis: {
							type: 'category',
							data: allhourStr,
						},
						yAxis: {
							type: 'value',
							minInterval: 1
						},
						series: [{
							name: '精力充沛人数',
							data: allTiredList[2],
							type: 'bar',
							itemStyle:{
								color:'#4472c4'
							}
						},{
							name: '中等疲劳人数',
							data: allEmotionList[1],
							type: 'bar',
							itemStyle:{
								color:'#ed7d31'
							}
						},{
							name: '疲劳程度较高人数',
							data: allTiredList[0],
							type: 'bar',
							itemStyle:{
								color:'#a5a5a5'
							}
						}]
					});
					let domSs = document.getElementById('groupSs')
					let wordcSs = this.$echarts.init(domSs);
					wordcSs.setOption({
						animation: false,
						title: {
							text: '情绪状况走势图',
							left: 'center'
						},
						legend: {
							left: 'center',
							bottom: 10,
						},
						xAxis: {
							type: 'category',
							data: allhourStr,
						},
						yAxis: {
							type: 'value',
							minInterval: 1
						},
						series: [{
							name: '压力较小人数',
							data: allStressList[2],
							type: 'bar',
							itemStyle:{
								color:'#4472c4'
							}
						},{
							name: '中等压力人数',
							data: allStressList[1],
							type: 'bar',
							itemStyle:{
								color:'#ed7d31'
							}
						},{
							name: '压力较大人数',
							data: allStressList[0],
							type: 'bar',
							itemStyle:{
								color:'#a5a5a5'
							}
						}]
					});
					let domAs = document.getElementById('groupAs')
					let wordcAs = this.$echarts.init(domAs);
					wordcAs.setOption({
						animation: false,
						title: {
							text: '情绪状况走势图',
							left: 'center'
						},
						legend: {
							left: 'center',
							bottom: 10,
						},
						xAxis: {
							type: 'category',
							data: allhourStr,
						},
						yAxis: {
							type: 'value',
							minInterval: 1
						},
						series: [{
							name: '波动较大',
							data: allStableList[2],
							type: 'bar',
							itemStyle:{
								color:'#a5a5a5'
							}
						},{
							name: '波动中等',
							data: allStableList[1],
							type: 'bar',
							itemStyle:{
								color:'#ed7d31'
							}
						},{
							name: '波动较小',
							data: allStableList[0],
							type: 'bar',
							itemStyle:{
								color:'#4472c4'
							}
						}]
					});
					let images = [wordcE.getDataURL(),wordcT.getDataURL(),wordcS.getDataURL(),wordcA.getDataURL(),wordcEs.getDataURL(),wordcTs.getDataURL(),wordcSs.getDataURL(),wordcAs.getDataURL()]
					up_wordimg_group({images,daytime,persion,list,highestEmo,higEmotionStr,higTiredStr,higStressStr,higStableStr,midEmotionStr,midTiredStr,midStressStr,midStableStr,lowEmotionStr,lowTiredStr,lowStressStr,lowStableStr}).then(res=>{
						if(res.code==200){
							this.$message.success('生成成功！')
							loading.close();
							const linkSource = `data:application/msword;base64,${res.data}`;
							const downloadLink = document.createElement('a');
							document.body.appendChild(downloadLink);
							downloadLink.href = linkSource;
							downloadLink.target = '_self';
							downloadLink.download = "团体报告.docx";
							downloadLink.click();
						}else{
							this.$message.error('生成失败！')
						}
					}).catch(()=>{
						this.$message.error('生成失败！')
					})
				});
			},
			getPieOpt(type, highestEmo) {
				let initdata = [];
				if (type == '情绪稳定性') {
					if (highestEmo.alow) {
						initdata.push({
							value: highestEmo.alow,
							name: '波动较小',
							itemStyle:{
								color:'#4472c4'
							}
						})
					}
					if (highestEmo.amid) {
						initdata.push({
							value: highestEmo.amid,
							name: '波动中等',
							itemStyle:{
								color:'#ed7d31'
							}
						})
					}
					if (highestEmo.ahig) {
						initdata.push({
							value: highestEmo.ahig,
							name: '波动较大',
							itemStyle:{
								color:'#a5a5a5'
							}
						})
					}
				}
				if (type == '情绪') {
					if (highestEmo.ehig) {
						initdata.push({
							value: highestEmo.ehig,
							name: '积极情绪',
							itemStyle:{
								color:'#4472c4'
							}
						})
					}
					if (highestEmo.emid) {
						initdata.push({
							value: highestEmo.emid,
							name: '心情中等',
							itemStyle:{
								color:'#ed7d31'
							}
						})
					}
					if (highestEmo.elow) {
						initdata.push({
							value: highestEmo.elow,
							name: '低于一般水平',
							itemStyle:{
								color:'#a5a5a5'
							}
						})
					}
				}
				if (type == '疲劳') {
					if (highestEmo.thig) {
						initdata.push({
							value: highestEmo.tlow,
							name: '精力充沛',
							itemStyle:{
								color:'#4472c4'
							}
						})
					}
					if (highestEmo.tmid) {
						initdata.push({
							value: highestEmo.tmid,
							name: '中等疲劳',
							itemStyle:{
								color:'#ed7d31'
							}
						})
					}
					if (highestEmo.thig) {
						initdata.push({
							value: highestEmo.thig,
							name: '疲劳程度较高',
							itemStyle:{
								color:'#a5a5a5'
							}
						})
					}
				}
				if (type == '压力') {
					if (highestEmo.slow) {
						initdata.push({
							value: highestEmo.slow,
							name: '放松',
							itemStyle:{
								color:'#4472c4'
							}
						})
					}
					if (highestEmo.smid) {
						initdata.push({
							value: highestEmo.smid,
							name: '稍有压力',
							itemStyle:{
								color:'#ed7d31'
							}
						})
					}
					if (highestEmo.shig) {
						initdata.push({
							value: highestEmo.shig,
							name: '压力较大',
							itemStyle:{
								color:'#a5a5a5'
							}
						})
					}
				}
				return {
					title: {
						text: `【${type}】状况统计结果`,
						left: 'center'
					},
					tooltip: {
						trigger: 'item',
					},
					legend: {
						left: 'center',
						bottom: 10,
					},
					animation: false,
					series: [{
						name: '情绪指数',
						type: 'pie',
						radius: '50%',
						data: initdata,
						label: {
							formatter: '{b} {d}%',
							backgroundColor: 'rgba(0,0,0,0.6)',
							color: '#FFFFFF',
							padding: 5,
							borderRadius: 5
						},
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}]
				};
			},
			exportWord(row,downtype='pdf') {
				const loading = this.$loading({
					lock: true,
					text: '文档正在生成中，请稍等',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
				});
				if (!this.emotionDate || !this.emotionendDate) {
					return this.$message.info('请选择开始和截止时间！')
				}
				person_word({
					emotionDate: this.emotionDate,
					emotionendDate: this.emotionendDate,
					...row
				}).then(res => {
					// wordviewRRI
					let domRRI = document.getElementById('wordviewRRI')
					let domE = document.getElementById('wordviewE')
					let domT = document.getElementById('wordviewT')
					let domS = document.getElementById('wordviewS')
					let eomtionArr = []
					let tiredArr = []
					let stressArr = []
					let timestr = []
					let hig_eomtion = [];
					let low_emotion = [];
					let hig_tired = [];
					let low_tired = [];
					let hig_stress = [];
					let low_stress = [];
					let totalE = 0;
					let totalT = 0;
					let totalS = 0;
					res.hourData.forEach(item => {
						if (item) {
							totalE += item.emotion_mean;
							totalT += item.tired_mean;
							totalS += item.stress_mean;
							eomtionArr.push(item.emotion_mean)
							tiredArr.push(item.tired_mean)
							stressArr.push(item.stress_mean)
							timestr.push(item.time)
							if (item.emotion_mean > 70) {
								hig_eomtion.push(item)
							}
							if (item.emotion_mean < 30) {
								low_emotion.push(item)
							}
							if (item.tired_mean > 70) {
								low_tired.push(item)
							}
							if (item.tired_mean < 30) {
								hig_tired.push(item)
							}
							if (item.stress_mean > 70) {
								low_stress.push(item)
							}
							if (item.stress_mean < 30) {
								hig_stress.push(item)
							}
						}
					})

					let avgemotion = (totalE / eomtionArr.length).toFixed(2);
					let avgtired = (totalT / eomtionArr.length).toFixed(2);
					let avgstress = (totalS / eomtionArr.length).toFixed(2);
					let rriChartData = [];
					let rridate = [];
					let longRri = '';
					let rriRes = res.rriRes.reverse()
					for (let i in rriRes) {
						let item = rriRes[i];
						if (longRri) {
							longRri += ',';
						}
						longRri += item.rri;

						let rristrArr = item.rri.split(',');
						for (let k in rristrArr) {
							let itemRRI = rristrArr[k];
							let times = item.date_int + parseInt(itemRRI);
							let now = moment(times).format('HH:mm')
							rriChartData.push(itemRRI)
							rridate.push(now)
						}
					}
					let wordviewRRI = this.$echarts.init(domRRI);
					let wordcE = this.$echarts.init(domE);
					let wordcT = this.$echarts.init(domT);
					let wordcS = this.$echarts.init(domS);
					wordviewRRI.setOption({
						xAxis: {
							type: 'category',
							data: rridate
						},
						yAxis: {
							type: 'value',
							boundaryGap: [0, '100%'],
							max: 1400,
							min: 0
						},
						series: [{
							name: '心率间隙',
							symbol: 'none',
							type: 'line',
							data: rriChartData
						}],
						animation: false
					})
					let imgbufRRI = wordviewRRI.getDataURL({
						pixelRatio: 2,
						backgroundColor: '#fff'
					});
					wordcE.setOption({
						xAxis: {
							data: timestr
						},
						yAxis: {
							type: 'value',
							max: 100,
							min: 0
						},
						series: [{
							// smooth: true,
							name: '心情',
							type: 'line',
							symbol: 'none',
							data: eomtionArr
						}],
						animation: false
					})
					let imgbufE = wordcE.getDataURL({
						pixelRatio: 2,
						backgroundColor: '#fff'
					});
					wordcT.setOption({
						xAxis: {
							data: timestr
						},
						yAxis: {
							type: 'value',
							max: 100,
							min: 0
						},
						series: [{
							name: '疲劳',
							type: 'line',
							symbol: 'none',
							data: tiredArr
						}],
						animation: false
					})
					let imgbufT = wordcT.getDataURL({
						pixelRatio: 2,
						backgroundColor: '#fff'
					});
					wordcS.setOption({
						xAxis: {
							data: timestr
						},
						yAxis: {
							type: 'value',
							max: 100,
							min: 0
						},
						series: [{
							name: '压力',
							type: 'line',
							symbol: 'none',
							data: stressArr
						}],
						animation: false
					})
					let imgbufS = wordcS.getDataURL({
						pixelRatio: 2,
						backgroundColor: '#fff'
					});
					let sdnemotion = standardDeviation(eomtionArr);
					let sdnstress = standardDeviation(stressArr);
					let sdntired = standardDeviation(tiredArr);
					this.$nextTick(() => {
						up_wordimg({
							sdnemotion,
							sdnstress,
							sdntired,
							covemotion: parseFloat(sdnemotion / avgemotion).toFixed(2),
							covstress: parseFloat(sdnstress / avgstress).toFixed(2),
							covtired: parseFloat(sdntired / avgtired).toFixed(2),
							avgstress,
							avgemotion,
							avgtired,
							longRri,
							imgE: imgbufE,
							imgT: imgbufT,
							imgS: imgbufS,
							imgRRI: imgbufRRI,
							emotionDate: this.emotionDate,
							emotionendDate: this.emotionendDate,
							imei: row.imei,
							nickname: row.nickname,
							gender: row.gender,
							birthday: row.birthday,
							hig_eomtion,
							low_emotion,
							hig_tired,
							low_tired,
							hig_stress,
							low_stress,
							downtype:downtype
						}).then(res => {
							this.$message.success('生成成功！')
							loading.close();
							const linkSource = `data:application/${downtype};base64,${res}`;
							// const linkSource = `data:application/msword;base64,${res}`;
							const downloadLink = document.createElement('a');
							document.body.appendChild(downloadLink);
							downloadLink.href = linkSource;
							downloadLink.target = '_self';
							downloadLink.download = row.nickname + "情绪报告.docx";
							downloadLink.click();

						}).catch(() => {
							this.$message.info('生成失败！')
							loading.close();
						})
					})
				}).catch(() => {
					this.$message.info('生成失败！')
					loading.close();
				})
			},
			exportsHzdata() {
				if (this.hzdata.length < 1) {
					return this.$message.info('暂无数据')
				}
				let dateStr = ''
				let d = new Date(this.hzsearchDate[0])
				dateStr = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' '
				if (this.hzsearchDate[0] != this.hzsearchDate[1]) {
					let ed = new Date(this.hzsearchDate[1])
					dateStr = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + '~' + ed.getFullYear() +
						'-' + (ed.getMonth() + 1) + '-' + ed.getDate()
				}
				let p = this.hzdata;
				let arrayData = [
					['时间', '心情指数', '疲劳指数', '压力指数']
				];
				for (let i in p) {
					let tstr = ''
					if (this.hzsearchDate[0] == this.hzsearchDate[1]) {
						tstr = dateStr + p[i].time
					}
					let row = [
						tstr, p[i].emotion, p[i].tired, p[i].stress
					];
					arrayData.push(row)
				}
				let arrayWorkSheet = XLSX.utils.aoa_to_sheet(arrayData);
				let workBook = {
					SheetNames: ['Sheet1'],
					Sheets: {
						'Sheet1': arrayWorkSheet
					},
				};
				let worksheet = workBook.Sheets['Sheet1'];
				XLSX.writeFile(workBook, "汇总数据.xlsx");
				bian_log(`导出汇总数据`)
			},
			abranchA(d) {
				this.abranchChange2 = [];
				let bzlist = [];
				this.abranchv3 = [];
				for (let i in this.abranchv1) {
					for (let j in this.abranch2) {
						if (this.abranchv1[i] == this.abranch2[j].parent) {
							bzlist.push(this.abranch2[j])
						}
					}
				}
				this.abranchChange2 = uniqueObj(bzlist, 'branch');
				this.persionShow = [];
				for (let i in this.persionList) {
					for (let j in this.abranchv1) {
						if (this.abranchv1[j] == this.persionList[i].branch_1) {
							this.persionShow.push(this.persionList[i])
						}
					}
				}
			},
			abranchB() {
				if (this.abranchv2.length > 0) {
					let newarr = [];
					this.abranchv3 = [];
					for (let i in this.persionShow) {
						for (let j in this.abranchv2) {
							if (this.persionShow[i].branch_2 == this.abranchv2[j]) {
								newarr.push(this.persionShow[i])
							}
						}
					}
					this.persionShow = newarr;
				}
			},
			searchHz() {
				const loading = this.$loading({
					lock: true,
					text: '正在查询，请稍候',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				if (this.hzsearchDate[0] == this.hzsearchDate[1]) {
					
					if (this.abranchv3.length > 0 ) {
						let imei = this.abranchv3
						// for (let i in this.persionShow) {
						// 	imei.push(this.persionShow[i].imei)
						// }
						// return loading.close();
						return personAlldata(imei, this.hzsearchDate[0]).then(res => {
							for (let i in res.dataLine) {
								if (res.dataLine[i].tired) {
									res.dataLine[i].tired = 100 - res.dataLine[i].tired;
								}
							}
							this.hzdata = res.dataLine
							loading.close()
							if (res.dataLine.length == 0) {
								return this.$message.info('暂无数据！')
							}
							this.$nextTick(() => {
								this.previewHzChartAll(res.dataLine)
							})
						}).catch(() => {
							loading.close()
							return this.$message.error('网络错误！')
						})
					} else if (this.abranchv1.length > 0) {
						let imei = []
						for (let i in this.persionShow) {
							imei.push(this.persionShow[i].imei)
						}
						// return loading.close();
						personAlldata(imei, this.hzsearchDate[0]).then(res => {
							for (let i in res.dataLine) {
								if (res.dataLine[i].tired) {
									res.dataLine[i].tired = 100 - res.dataLine[i].tired;
								}
							}
							this.hzdata = res.dataLine
							loading.close()
							if (res.dataLine.length == 0) {
								return this.$message.info('暂无数据！')
							}
							this.$nextTick(() => {
								this.previewHzChartAll(res.dataLine)
							})
						}).catch(() => {
							loading.close()
							return this.$message.error('网络错误！')
						})
					} else {
						loading.close()
						return this.$message.info('请选择人员')
					}
				} else {
					// 查询天数据
					if (this.abranchv3.length > 0 ) {
						let imei = this.abranchv3
					// if (this.abranchv2.length > 0 || this.abranchv1.length > 0) {
					// 	let imei = []
					// 	for (let i in this.persionShow) {
					// 		imei.push(this.persionShow[i].imei)
					// 	}
						getAllbarnchPersion(imei, this.hzsearchDate[0], this.hzsearchDate[1]).then((res => {
							for (let i in res.dataLine) {
								if (res.dataLine[i].tired) {
									res.dataLine[i].tired = 100 - res.dataLine[i].tired;
								}
							}
							loading.close()
							if (res.dataLine.length == 0) {
								return this.$message.info('暂无数据！')
							}
							this.hzdata = res.dataLine
							this.$nextTick(() => {
								this.previewHzChartAll(res.dataLine)
							})
						})).catch(() => {
							loading.close()
							return this.$message.error('网络错误！')
						})
					} else if(this.abranchv1.length > 0) {
						let imei = []
						for (let i in this.persionShow) {
							imei.push(this.persionShow[i].imei)
						}
						getAllbarnchPersion(imei, this.hzsearchDate[0], this.hzsearchDate[1]).then((res => {
							for (let i in res.dataLine) {
								if (res.dataLine[i].tired) {
									res.dataLine[i].tired = 100 - res.dataLine[i].tired;
								}
							}
							loading.close()
							if (res.dataLine.length == 0) {
								return this.$message.info('暂无数据！')
							}
							this.hzdata = res.dataLine
							this.$nextTick(() => {
								this.previewHzChartAll(res.dataLine)
							})
						})).catch(() => {
								loading.close()
								return this.$message.error('网络错误！')
							})
					} else {
						loading.close()
						return this.$message.info('请选择人员')
					}
				}
			},
			showhz(reset) {
				if (reset) {
					this.abranchv1 = []
					this.abranchv2 = []
					this.abranchv3 = []
					this.abranchChange2 = []
				}
				this.hzsearchDate = [new Date().getTime(), new Date().getTime()]
				this.hzpreviewVisible = true;
				// let level = sessionStorage.getItem('level')
				// let company = sessionStorage.getItem('company')
				// let branch_content = sessionStorage.getItem('branch-content')

				let level = sessionStorage.getItem('level');
				let company = sessionStorage.getItem('company')
				let phone = localStorage.getItem('phone')
				getAllbarnch(company, phone, level).then(res => {
					if (res.status == 200) {
						this.persionList = res.persion;
						this.persionShow = res.persion;
						this.abranch1 = [];
						for (let i in res.data) {
							this.abranch1.push({
								branch: res.data[i].branch,
								index: i
							})
							let childarr = JSON.parse(res.data[i].children)
							if (childarr.length > 0) {
								for (let j in childarr) {
									this.abranch2.push({
										branch: childarr[j].branch,
										index: j,
										parent: res.data[i].branch
									})
								}
							}
						}
					}
				})
			},
			previewHzChartAll(data) {
				this.emotionHzChart(data)
				this.tiredHzChart(data)
				this.stressHzChart(data)
				let emotionAll = [];
				let stressAll = [];
				let tiredAll = [];
				let dateAll = [];
				data.forEach(item => {
					emotionAll.push(item.emotion)
					stressAll.push(item.stress)
					tiredAll.push(item.tired)
					dateAll.push(item.time)
				})
				let chartDom = document.getElementById('hzpreview-chart')
				if (this.preview_chart_hz != '') {
					this.preview_chart_hz.dispose()
				}
				this.preview_chart_hz = this.$echarts.init(chartDom);
				let option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							crossStyle: {
								color: '#999'
							}
						}
					},
					legend: {
						data: ['心情', '压力', '疲劳']
					},
					xAxis: [{
						type: 'category',
						data: dateAll,
						axisPointer: {
							type: 'shadow'
						}
					}],
					yAxis: {
						splitNumber: 10,
						// show: false,
						max: 100,
						type: 'value',
						axisLabel: {
							textStyle: {
								color: function(value, index) {
									if (value <= 25) {
										return 'red'
									} else if (value <= 50) {
										return '#FBDB0F'
									} else {
										return 'green'
									}
								},
							},
							formatter: function(value, index) {
								if (value == 50 || value == 100) return '';
								return value;
							}
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: '#ccc'
							}
						}
					},
					series: [{
							name: '心情',
							type: 'line',
							barGap: 0,
							symbol: 'circle',
							symbolSize: 10,
							emphasis: {
								focus: 'series'
							},
							markLine,
							data: emotionAll
						},
						{
							symbol: 'rect',
							symbolSize: 10,
							name: '压力',
							type: 'line',
							emphasis: {
								focus: 'series'
							},
							markLine,
							data: stressAll
						},
						{
							symbol: 'triangle',
							symbolSize: 10,
							name: '疲劳',
							type: 'line',
							emphasis: {
								focus: 'series'
							},
							markLine,
							data: tiredAll
						},
					]
				};
				this.preview_chart_hz.clear()
				this.preview_chart_hz.setOption(option);
			},
			departmentList() {
				department_list(this.company, this.searchDepartment1, this.searchDepartment2).then(res => {
					if (res.status != 200) {
						return this.$message.error('获取部门列表失败')
					}
					let branch = res.data.branch;
					branch.forEach(item => {
						item.children = JSON.parse(item.children)
					})
					this.companyData = branch;
					this.department1List = branch;
					//管理员部门查询筛选权限
					let branch_content = sessionStorage.getItem('branch-content')
					let branch_level = sessionStorage.getItem('level')
					let branchArr = [];
					if (branch_level == 1) {
						this.branchArr = []
					} else {
						branchArr = branch_content.split('|');
						this.branchArr = branchArr;
					}
					if (branchArr[0]) {
						this.searchDepartment1 = branchArr[0]
						this.department1List.forEach(item => {
							if (item.branch == this.searchDepartment1) {
								this.department2List = item.children
							}
						})
					}
					if (branchArr[1]) {
						this.searchDepartment2 = branchArr[1]
					}
					this.personnelList()
				})
			},
			personnelList() {
				const loading = this.$loading({
					lock: true,
					text: '正在查询，请稍候',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				emotion_list(this.company, this.searchName, this.searchMajor, this
					.searchDepartment1,
					this.searchDepartment2, this.emotionDate, this.pagenum, this.pagesize, this.emotionendDate).then(
					res => {
						if (res.status == 400) {
							loading.close();
							return this.$message.error(res.msg || '')
						}
						if (res.status != 200) {
							loading.close();
							return this.$message.info('暂无数据！')
						}
						this.alldata = res.data.alldata;
						// for (let i in res.data.dayIndex) {
						// 	res.data.dayIndex[i].stress_mean = ((res.data.dayIndex[i].emotion_mean - res.data
						// 		.dayIndex[
						// 			i].tired_mean + 100) / 2).toFixed();

						// 	if (res.data.dayIndex[i].tired_mean > 0) {
						// 		res.data.dayIndex[i].tired_mean = 100 - res.data.dayIndex[i].tired_mean;
						// 	}
						// }
						this.personnelData = res.data.dayIndex;
						this.personnelLink = res.data.url;
						this.total = res.data.total;
						loading.close();
					}).catch(err => {
					loading.close();
					this.$notify.error({
						title: '数据加载失败！',
						message: err.message
					});
				})
			},
			formatEmotion(index) {
				if (index >= 0 && index <= 49) {
					return index + '(有点差)'
				}
				if (index >= 50 && index < 70) {
					return index + '(心情平和)'
				}
				if (index >= 70 && index <= 100) {
					return index + '(比较愉悦)'
				}
			},
			formatStress(index) {
				if (index >= 0 && index < 50) {
					return index + '(较为松弛)'
				}
				if (index >= 50 && index < 70) {
					return index + '(稍有压力)'
				}
				if (index >= 70 && index <= 100) {
					return index + '(压力较大)'
				}
			},
			formatTired(index) {
				if (index >= 0 && index < 55) {
					return index + '(精力充沛)'
				}
				if (index >= 50 && index < 70) {
					return index + '(略有疲惫)'
				}
				if (index >= 70 && index <= 100) {
					return index + '(感到劳累)'
				}
				return index;
			},
			choiceA() {
				this.searchDepartment2 = null
				this.department2List = []
				this.department1List.forEach(item => {
					if (item.branch == this.searchDepartment1) {
						this.department2List = item.children
					}
				})
			},
			reset() {
				this.searchName = '';
				this.searchMajor = '';
				if (this.branchArr.length == 0) {
					this.searchDepartment1 = null
					this.searchDepartment2 = null
					this.department2List = []
				}
				if (this.branchArr.length == 1) {
					this.searchDepartment2 = null
				}

				this.emotionDate = moment().startOf('day').valueOf() + 360 * 60000;
				this.emotionendDate = moment().valueOf();
				this.personnelList()
			},
			search() {
				this.personnelList()
			},
			handleSizeChange(newSize) {
				this.pagesize = newSize;
				this.personnelList();
			},
			handleCurrentChange(newPage) {
				this.pagenum = newPage;
				this.personnelList();
			},
			previewData() {
				// person_day(this.previewImei, this.searchDate[0]).then(res => {
				// 	if (res.status != 200) {
				// 		return this.$message.error('获取概览失败')
				// 	}
				// 	this.emotionAllHour = res.data.emotionAllHour;
				// 	this.stressAllHour = res.data.stressAllHour;
				// 	this.tiredAllHour = res.data.tiredAllHour;
				// 	this.personnelDayLink = res.data.url;

				// 	for(let i in this.tiredAllHour){
				// 		if(this.tiredAllHour[i]>0){
				// 			this.tiredAllHour[i] = 100-this.tiredAllHour[i];
				// 		}
				// 	}

				// 	let emotionA = 0;
				// 	let emotionB = 0;
				// 	let emotionC = 0;
				// 	let tiredA = 0;
				// 	let tiredB = 0;
				// 	let tiredC = 0;
				// 	let stressA = 0;
				// 	let stressB = 0;
				// 	let stressC = 0;
				// 	let emotionLn = 0;
				// 	let tiredLn = 0;
				// 	let stressLn = 0;
				// 	this.emotionAllHour.forEach(item => {
				// 		if (item) {
				// 			emotionLn += 1;
				// 			if (item >= 0 && item <= 24) {
				// 				emotionA += 1
				// 			}
				// 			if (item >= 25 && item < 50) {
				// 				emotionB += 1
				// 			}
				// 			if (item >= 50 && item <= 100) {
				// 				emotionC += 1
				// 			}
				// 		}
				// 	})
				// 	this.stressAllHour.forEach(item => {
				// 		if (item) {
				// 			stressLn += 1;
				// 			if (item >= 0 && item <= 24) {
				// 				stressC += 1
				// 			}
				// 			if (item >= 25 && item < 50) {
				// 				stressB += 1
				// 			}
				// 			if (item >= 50 && item <= 100) {
				// 				stressA += 1
				// 			}
				// 		}
				// 	})
				// 	this.tiredAllHour.forEach(item => {
				// 		if (item) {
				// 			tiredLn += 1;
				// 			if (item >= 0 && item <= 24) {
				// 				tiredC += 1
				// 			}
				// 			if (item >= 25 && item < 50) {
				// 				tiredB += 1
				// 			}
				// 			if (item >= 50 && item <= 100) {
				// 				tiredA += 1
				// 			}
				// 		}
				// 	})
				// 	this.percentEmotion = [{
				// 		value: emotionA
				// 	}, {
				// 		value: emotionB
				// 	}, {
				// 		value: emotionC
				// 	}]
				// 	this.percentStress = [{
				// 		value: stressA
				// 	}, {
				// 		value: stressB
				// 	}, {
				// 		value: stressC
				// 	}]
				// 	this.percentTired = [{
				// 		value: tiredA
				// 	}, {
				// 		value: tiredB
				// 	}, {
				// 		value: tiredC
				// 	}]
				// 	this.emotionPercentNum = [Math.round(emotionA / emotionLn * 100), Math.round(emotionB /
				// 		emotionLn * 100), Math.round(emotionC / emotionLn * 100)]
				// 	this.stressPercentNum = [Math.round(stressA / stressLn * 100), Math.round(stressB / stressLn *
				// 		100), Math.round(stressC / stressLn * 100)]
				// 	this.tiredPercentNum = [Math.round(tiredA / tiredLn * 100), Math.round(tiredB / tiredLn * 100),
				// 		Math.round(tiredC / tiredLn * 100)
				// 	]
				// 	this.$nextTick(() => {
				// 		this.previewChart()
				// 		this.emotionChart()
				// 		this.tiredChart()
				// 		this.stressChart()
				// 	})
				// });
				person_min(this.previewImei, this.searchDate[0]).then(res => {

					if (res.status == 200) {
						this.minChartData = res.emotionArr;
						this.minChartTime = res.timeLine

						this.emotionAll = [];
						this.stressAll = [];
						this.tiredAll = [];

						let emotionA = 0;
						let emotionB = 0;
						let emotionC = 0;
						let tiredA = 0;
						let tiredB = 0;
						let tiredC = 0;
						let stressA = 0;
						let stressB = 0;
						let stressC = 0;
						let emotionLn = 0;
						let tiredLn = 0;
						let stressLn = 0;
						for (let i in this.minChartData) {
							if (this.minChartData[i].emotion_index) {
								emotionLn += 1;
								if (this.minChartData[i].emotion_index >= 0 && this.minChartData[i]
									.emotion_index <= 49) {
									emotionA += 1
								}
								if (this.minChartData[i].emotion_index >= 50 && this.minChartData[i]
									.emotion_index < 70) {
									emotionB += 1
								}
								if (this.minChartData[i].emotion_index >= 70 && this.minChartData[i]
									.emotion_index <= 100) {
									emotionC += 1
								}
							}
							if (this.minChartData[i].tired_index) {
								this.minChartData[i].tired_index = 100 - this.minChartData[i].tired_index;
								tiredLn += 1;
								if (this.minChartData[i].tired_index >= 0 && this.minChartData[i]
									.tired_index <=
									49) {
									tiredA += 1
								}
								if (this.minChartData[i].tired_index >= 50 && this.minChartData[i]
									.tired_index <
									69) {
									tiredB += 1
								}
								if (this.minChartData[i].tired_index >= 70 && this.minChartData[i]
									.tired_index <=
									100) {
									tiredC += 1
								}
							}

							if (this.minChartData[i].stress_index) {
								stressLn += 1;
								if (this.minChartData[i].stress_index >= 0 && this.minChartData[i]
									.stress_index <=
									49) {
									stressA += 1
								}
								if (this.minChartData[i].stress_index >= 50 && this.minChartData[i]
									.stress_index <
									70) {
									stressB += 1
								}
								if (this.minChartData[i].stress_index >= 70 && this.minChartData[i]
									.stress_index <=
									100) {
									stressC += 1
								}
							}
						}
						this.percentEmotion = [{
							value: emotionA
						}, {
							value: emotionB
						}, {
							value: emotionC
						}]
						this.percentStress = [{
							value: stressA
						}, {
							value: stressB
						}, {
							value: stressC
						}]
						this.percentTired = [{
							value: tiredA
						}, {
							value: tiredB
						}, {
							value: tiredC
						}]
						this.emotionPercentNum = [Math.round(emotionA / emotionLn * 100), Math.round(
							emotionB /
							emotionLn * 100), Math.round(emotionC / emotionLn * 100)]
						this.stressPercentNum = [Math.round(stressA / stressLn * 100), Math.round(stressB /
							stressLn *
							100), Math.round(stressC / stressLn * 100)]
						this.tiredPercentNum = [Math.round(tiredA / tiredLn * 100), Math.round(tiredB /
								tiredLn *
								100),
							Math.round(tiredC / tiredLn * 100)
						]
						this.$nextTick(() => {
							this.previewChart()
							this.emotionChart()
							this.tiredChart()
							this.stressChart()
						})
					}
				})
			},
			previewAllData() {
				person_all_day(this.previewImei, this.searchDate[0], this.searchDate[1]).then(res => {
					if (res.status != 200) {
						return this.$message.error('获取概览失败')
					}
					this.emotionAll = res.data.emotionAll;
					this.stressAll = res.data.stressAll;
					// this.stressAll = [];
					this.tiredAll = res.data.tiredAll;
					this.dateAll = res.data.dateAll;
					this.personnelDayLink = res.data.url;
					let emotionA = 0;
					let emotionB = 0;
					let emotionC = 0;
					let tiredA = 0;
					let tiredB = 0;
					let tiredC = 0;
					let stressA = 0;
					let stressB = 0;
					let stressC = 0;
					let emotionLn = 0;
					let tiredLn = 0;
					let stressLn = 0;
					this.emotionAll.forEach((item, i) => {
						if (item) {
							// let hi = parseInt((item - res.data.tiredAll[i] + 100) / 2);
							// this.stressAll.push(hi)
							emotionLn += 1;
							if (item >= 0 && item <= 49) {
								emotionA += 1
							}
							if (item >= 50 && item < 70) {
								emotionB += 1
							}
							if (item >= 70 && item <= 100) {
								emotionC += 1
							}
						} else {
							this.stressAll.push(null)
						}

					})
					this.stressAll.forEach(item => {
						if (item) {
							stressLn += 1;
							if (item >= 0 && item <= 49) {
								stressA += 1
							}
							if (item >= 50 && item < 70) {
								stressB += 1
							}
							if (item >= 70 && item <= 100) {
								stressC += 1
							}
						}
					})
					this.tiredAll.forEach(item => {
						if (item) {
							tiredLn += 1;
							if (item >= 0 && item <= 49) {
								tiredA += 1
							}
							if (item >= 50 && item < 70) {
								tiredB += 1
							}
							if (item >= 70 && item <= 100) {
								tiredC += 1
							}
						}
					})
					this.percentEmotion = [{
						value: emotionA
					}, {
						value: emotionB
					}, {
						value: emotionC
					}]
					this.percentStress = [{
						value: stressA
					}, {
						value: stressB
					}, {
						value: stressC
					}]
					this.percentTired = [{
						value: tiredA
					}, {
						value: tiredB
					}, {
						value: tiredC
					}]
					this.emotionPercentNum = [Math.round(emotionA / emotionLn * 100), Math.round(emotionB /
						emotionLn * 100), Math.round(emotionC / emotionLn * 100)]
					this.stressPercentNum = [Math.round(stressA / stressLn * 100), Math.round(stressB /
						stressLn *
						100), Math.round(stressC / stressLn * 100)]
					this.tiredPercentNum = [Math.round(tiredA / tiredLn * 100), Math.round(tiredB /
							tiredLn * 100),
						Math.round(tiredC / tiredLn * 100)
					]
					this.$nextTick(() => {
						this.previewChartAll()
						this.emotionChart()
						this.tiredChart()
						this.stressChart()
					})
				})
			},
			preview(row) {
				this.searchDate = [new Date().getTime(), new Date().getTime()]
				this.previewVisible = true;
				this.previewImei = row.imei;
				this.rowData = row;
				this.previewData()
			},
			searchPreview() {
				if (!this.searchDate[0]) {
					return this.$message.error('请选择开始时间')
				}
				if (!this.searchDate[1]) {
					return this.$message.error('请选择结束时间')
				}
				if (this.searchDate[1] > this.searchDate[0]) {
					this.previewAllData()
				} else {
					this.previewData()
				}
			},
			previewChart() {
				let chartDom = document.getElementById('preview-chart')
				if (this.preview_chart != '') {
					this.preview_chart.dispose()
				}
				this.preview_chart = this.$echarts.init(chartDom);
				let emotionAllHour = [];
				let stressAllHour = [];
				let tiredAllHour = [];
				let minChartTime = this.minChartTime.reverse();
				let minChartData = this.minChartData;
				for (let i in minChartData) {
					emotionAllHour.unshift(minChartData[i].emotion_index)
					stressAllHour.unshift(minChartData[i].stress_index)
					tiredAllHour.unshift(minChartData[i].tired_index)
				}
				let option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							crossStyle: {
								color: '#999'
							}
						},
						formatter: '时间:{b}<br />心情 {c}<br />{a2} {c2}<br />{a1} {c1}'
					},
					legend: {
						data: ['心情', '压力', '疲劳']
					},
					xAxis: [{
						type: 'category',
						data: minChartTime,
						axisPointer: {
							type: 'shadow'
						}
					}],
					yAxis: {
						splitNumber: 10,
						// show: false,
						max: 100,
						type: 'value',
						axisLabel: {
							textStyle: {
								color: function(value, index) {
									if (value <= 25) {
										return 'red'
									} else if (value <= 50) {
										return '#FBDB0F'
									} else {
										return 'green'
									}
								},
							},
							formatter: function(value, index) {
								if (value == 50 || value == 100) return '';
								return value;
							}
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: '#ccc'
							}
						}
					},
					series: [{
							name: '心情',
							type: 'line',
							barGap: 0,
							symbol: 'circle',
							symbolSize: 8,
							emphasis: {
								focus: 'series'
							},
							markLine,
							data: emotionAllHour
						},
						{
							name: '压力',
							symbol: 'rect',
							symbolSize: 8,
							type: 'line',
							emphasis: {
								focus: 'series'
							},
							// markLine,
							data: stressAllHour
						},
						{
							name: '疲劳',
							type: 'line',
							symbol: 'triangle',
							symbolSize: 8,
							emphasis: {
								focus: 'series'
							},
							// markLine,
							data: tiredAllHour
						},
					]
				};
				this.preview_chart.clear()
				this.preview_chart.setOption(option);
			},
			previewChartAll() {
				let chartDom = document.getElementById('preview-chart')
				if (this.preview_chart != '') {
					this.preview_chart.dispose()
				}
				this.preview_chart = this.$echarts.init(chartDom);
				let emotionAll = this.emotionAll;
				let stressAll = this.stressAll;
				let tiredAll = this.tiredAll;
				let dateAll = this.dateAll;
				let option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							crossStyle: {
								color: '#999'
							}
						}
					},
					legend: {
						data: ['心情', '压力', '疲劳']
					},
					xAxis: [{
						type: 'category',
						data: dateAll,
						axisPointer: {
							type: 'shadow'
						}
					}],
					yAxis: {
						splitNumber: 10,
						// show: false,
						max: 100,
						type: 'value',
						axisLabel: {
							textStyle: {
								color: function(value, index) {
									if (value <= 25) {
										return 'red'
									} else if (value <= 50) {
										return '#FBDB0F'
									} else {
										return 'green'
									}
								},
							},
							formatter: function(value, index) {
								if (value == 50 || value == 100) return '';
								return value;
							}
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: '#ccc'
							}
						}
					},
					series: [{
							name: '心情',
							type: 'line',
							barGap: 0,
							symbol: 'circle',
							symbolSize: 8,
							emphasis: {
								focus: 'series'
							},
							markLine,
							data: emotionAll
						},
						{
							name: '压力',
							type: 'line',
							symbolSize: 8,
							symbol: 'rect',
							emphasis: {
								focus: 'series'
							},
							markLine,
							data: stressAll
						},
						{
							name: '疲劳',
							symbol: 'triangle',
							symbolSize: 8,
							type: 'line',
							emphasis: {
								focus: 'series'
							},
							markLine,
							data: tiredAll
						},
					]
				};
				this.preview_chart.clear()
				this.preview_chart.setOption(option);
			},
			emotionChart() {
				let dom = document.getElementById('emotion-chart')
				if (this.emotionDom != "") {
					this.emotionDom.dispose()
				}
				this.emotionDom = this.$echarts.init(dom);
				let colorList = ['#FF0036', '#FBDB0F', '#55CF04']
				let data = this.percentEmotion.sort(util.sortObject)
				let option = {
					series: [{
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						labelLine: {
							show: false
						},
						data: data,
						itemStyle: {
							normal: {
								color: function(colors) {
									return colorList[colors.dataIndex]
								}
							}
						}
					}]
				};
				this.emotionDom.clear()
				this.emotionDom.setOption(option);
			},
			emotionHzChart(o_data) {
				let perA = {
					// name:'心情指数',
					value: 0
				}
				let perB = {
					// name:'心情指数',
					value: 0
				}
				let perC = {
					// name:'心情指数',
					value: 0
				}
				for (let i in o_data) {
					if (!o_data[i].emotion) {
						o_data.splice(i, 1)
					}
					if (o_data[i].emotion <= 25) {
						perA.value += 1;
					} else if (o_data[i].emotion <= 50) {
						perB.value += 1;
					} else {
						perC.value += 1;
					}
				}
				this.emotion_hzchartList = [(perC.value / o_data.length * 100).toFixed(1), (perB.value / o_data.length *
					100).toFixed(1), (perA.value / o_data.length * 100).toFixed(1)]
				let data = [perC, perB, perA]
				let dom = document.getElementById('emotion-hzchart')
				if (this.emotion_hzchart != "") {
					this.emotion_hzchart.dispose()
				}
				this.emotion_hzchart = this.$echarts.init(dom);
				let colorList = ['#55CF04', '#ffff7f', '#FF0036']
				let option = {
					series: [{
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						labelLine: {
							show: false
						},
						data: data,
						itemStyle: {
							normal: {
								color: function(colors) {
									return colorList[colors.dataIndex]
								}
							}
						}
					}]
				};
				this.emotion_hzchart.clear()
				this.emotion_hzchart.setOption(option);
			},
			tiredHzChart(o_data) {
				let perA = {
					// name:'心情指数',
					value: 0
				}
				let perB = {
					// name:'心情指数',
					value: 0
				}
				let perC = {
					// name:'心情指数',
					value: 0
				}
				for (let i in o_data) {
					if (!o_data[i].emotion) {
						o_data.splice(i, 1)
					}
					if (o_data[i].tired <= 25) {
						perA.value += 1;
					} else if (o_data[i].tired <= 50) {
						perB.value += 1;
					} else {
						perC.value += 1;
					}
				}
				this.tired_hzchartList = [(perC.value / o_data.length * 100).toFixed(1), (perB.value / o_data.length * 100)
					.toFixed(1), (perA.value / o_data.length * 100).toFixed(1)
				]
				let data = [perC, perB, perA]
				let dom = document.getElementById('tired-hzchart')
				if (this.tired_hzchart != "") {
					this.tired_hzchart.dispose()
				}
				this.tired_hzchart = this.$echarts.init(dom);
				let colorList = ['#55CF04', '#ffff7f', '#FF0036']
				let option = {
					series: [{
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						labelLine: {
							show: false
						},
						data: data,
						itemStyle: {
							normal: {
								color: function(colors) {
									return colorList[colors.dataIndex]
								}
							}
						}
					}]
				};
				this.tired_hzchart.clear()
				this.tired_hzchart.setOption(option);
			},
			stressHzChart(o_data) {
				let perA = {
					value: 0
				}
				let perB = {
					value: 0
				}
				let perC = {
					value: 0
				}
				for (let i in o_data) {
					if (!o_data[i].emotion) {
						o_data.splice(i, 1)
					}
					if (o_data[i].stress <= 25) {
						perA.value += 1;
					} else if (o_data[i].stress <= 50) {
						perB.value += 1;
					} else {
						perC.value += 1;
					}
				}
				this.stress_hzchartList = [(perC.value / o_data.length * 100).toFixed(1), (perB.value / o_data.length *
					100).toFixed(1), (perA.value / o_data.length * 100).toFixed(1)]
				let data = [perC, perB, perA]
				let dom = document.getElementById('stress-hzchart')
				if (this.stress_hzchart != "") {
					this.stress_hzchart.dispose()
				}
				this.stress_hzchart = this.$echarts.init(dom);
				let colorList = ['#55CF04', '#ffff7f', '#FF0036']
				let option = {
					series: [{
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						labelLine: {
							show: false
						},
						data: data,
						itemStyle: {
							normal: {
								color: function(colors) {
									return colorList[colors.dataIndex]
								}
							}
						}
					}]
				};
				this.stress_hzchart.clear()
				this.stress_hzchart.setOption(option);
			},
			tiredChart() {
				let dom = document.getElementById('tired-chart')
				if (this.tiredDom != "") {
					this.tiredDom.dispose()
				}
				this.tiredDom = this.$echarts.init(dom);
				let colorList = ['#55CF04', '#FBDB0F', '#FF0036']
				let data = this.percentTired.sort(util.sortObject)
				let option = {
					series: [{
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						labelLine: {
							show: false
						},
						data: data,
						itemStyle: {
							normal: {
								color: function(colors) {
									return colorList[colors.dataIndex]
								}
							}
						}
					}]
				};
				this.tiredDom.clear()
				this.tiredDom.setOption(option);
			},
			stressChart() {
				let dom = document.getElementById('stress-chart')
				if (this.stressDom != "") {
					this.stressDom.dispose()
				}
				this.stressDom = this.$echarts.init(dom);
				let colorList = ['#55CF04', '#FBDB0F', '#FF0036']
				let data = this.percentStress.sort(util.sortObject)
				let option = {
					series: [{
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						labelLine: {
							show: false
						},
						data: data,
						itemStyle: {
							normal: {
								color: function(colors) {
									return colorList[colors.dataIndex]
								}
							}
						}
					}]
				};
				this.stressDom.clear()
				this.stressDom.setOption(option);
			},
			closePreview() {
				this.previewVisible = false
			},
			exportDataEcexl() {
				let p = this.alldata;
				let arrayData = [
					['姓名', '职务', '一级部门', '班组', '心情指数', '疲劳指数', '压力指数', '步数(步)', '心率(bmp)',
						'血压(mmHg)', '血压(%)',
						'体温(°C)', '日期'
					]
				];
				for (let i in p) {
					let row = [
						p[i].nickname, p[i].job, p[i].branch_1, p[i].branch_2,
						p[i]
						.emotion_mean, p[i].tired_mean, p[i].stress_mean, p[i].pedometer, p[i].heartRate,
						p[i]
						.pressure, p[i].oxygen, p[i].temperature, p[i].dateStr
					];
					arrayData.push(row)
				}
				// 将数据转成workSheet
				let arrayWorkSheet = XLSX.utils.aoa_to_sheet(arrayData);
				// let jsonWorkSheet = xlsx.utils.json_to_sheet(jsonData);
				// 构造workBook
				let workBook = {
					SheetNames: ['Sheet1'],
					Sheets: {
						'Sheet1': arrayWorkSheet
					},
				};
				let worksheet = workBook.Sheets['Sheet1'];
				// 将workBook写入文件
				XLSX.writeFile(workBook, "员工当日数据.xlsx");
				bian_log(`导出数据`)
			},
			exportminData() {
				let rd = this.rowData;
				let p = this.minChartData;
				if (this.dateAll.length) {
					return this.exportdayData()
				}

				let arrayData = [
					['日期', '时间', '姓名', '职务', '一级部门', '班组', '心情指数', '疲劳指数', '压力指数']
				];
				for (let i in p) {
					let row = [
						rd.dateStr, p[i].minutes, rd.nickname, rd.job, rd.branch_1, rd
						.branch_2,
						p[i].emotion_index, p[i].tired_index, p[i].stress_index
					];
					arrayData.push(row)
				}
				// 将数据转成workSheet
				let arrayWorkSheet = XLSX.utils.aoa_to_sheet(arrayData);
				// let jsonWorkSheet = xlsx.utils.json_to_sheet(jsonData);
				// 构造workBook
				let workBook = {
					SheetNames: ['Sheet1'],
					Sheets: {
						'Sheet1': arrayWorkSheet
					},
				};
				let worksheet = workBook.Sheets['Sheet1'];
				// 将workBook写入文件
				XLSX.writeFile(workBook, "员工当日数据.xlsx");
				bian_log(`导出员工当日数据`)
			},
			exportdayData() {
				let rd = this.rowData;
				let p = this.dateAll;

				let arrayData = [
					['日期', '姓名', '职务', '一级部门', '心情指数', '疲劳指数', '压力指数']
				];
				for (let i in p) {
					let row = [
						p[i], rd.nickname, rd.job, rd.branch_1,
						this.emotionAll[i], this.tiredAll[i], this.stressAll[i]
					];
					arrayData.push(row)
				}
				// 将数据转成workSheet
				let arrayWorkSheet = XLSX.utils.aoa_to_sheet(arrayData);
				// let jsonWorkSheet = xlsx.utils.json_to_sheet(jsonData);
				// 构造workBook
				let workBook = {
					SheetNames: ['Sheet1'],
					Sheets: {
						'Sheet1': arrayWorkSheet
					},
				};
				let worksheet = workBook.Sheets['Sheet1'];
				// 将workBook写入文件
				XLSX.writeFile(workBook, "员工多日情绪数据.xlsx");
				bian_log(`导出员工多日情绪数据`)
			}
		},
		created() {
			if (this.$route.query.imei) {
				this.previewVisible = true;
				this.previewImei = this.$route.query.imei;
				this.previewData()
			}
			let level = sessionStorage.getItem('level');
			let company = sessionStorage.getItem('company')
			let phone = localStorage.getItem('phone')
			getAllbarnch(company, phone, level).then(res => {
				if (res.status == 200) {
					this.persionList = res.persion;
					this.persionShow = res.persion;
					this.abranch1 = [];
					for (let i in res.data) {
						this.abranch1.push({
							branch: res.data[i].branch,
							index: i
						})
						let childarr = JSON.parse(res.data[i].children)
						if (childarr.length > 0) {
							for (let j in childarr) {
								this.abranch2.push({
									branch: childarr[j].branch,
									index: j,
									parent: res.data[i].branch
								})
							}
						}
					}
				}
			})
			this.company = company;
			this.emotionDate = moment().startOf('day').valueOf() + 360 * 60000;
			this.emotionendDate = moment().valueOf();
			this.departmentList();
			this.searchDate = [new Date().getTime(), new Date().getTime()]
		},
	};

	function uniqueObj(arr, u_key) {
		let map = new Map()
		arr.forEach((item, index) => {
			if (!map.has(item[u_key])) {
				map.set(item[u_key], item)
			}
		})
		return [...map.values()]
	}
</script>
<style lang='less' scoped>
	.serach-box {
		display: flex;
		justify-content: flex-end;
	}

	.chart-box {
		width: 100%;
		height: 380px;
	}

	#preview-chart {
		width: 100%;
		height: 100%;
	}

	.pie-list {
		display: flex;
		justify-content: space-between;
	}

	.pie-box {
		height: 200px;
		width: 32%;
	}

	.pie-title {
		height: 50px;
		line-height: 50px;
		font-size: 16px;
		font-weight: 500;
		color: #333;
		text-align: center;
	}

	.pie-main {
		display: flex;
		justify-content: space-around;
	}

	#emotion-chart,
	#tired-chart,
	#stress-chart,
	#emotion-hzchart,
	#tired-hzchart,
	#stress-hzchart,
	#stress-hzchart {
		width: 150px;
		height: 150px;
	}

	.color-a {
		background-color: #55CF04;
	}

	.color-b {
		background-color: #FBDB0F;
	}

	.color-bc {
		background-color: #ffff7f;
	}

	.color-c {
		background-color: #FF0036;
	}

	.percentage-list {
		height: 150px;
	}

	.percentage-item {
		height: 50px;
		display: flex;
		justify-content: space-between;
	}

	.item-name {
		display: flex;
		justify-content: flex-start;
		margin-right: 20px;
	}

	.item-icn {
		height: 20px;
		width: 20px;
		margin-top: 15px;
		margin-right: 5px;
	}

	.name-text {
		height: 50px;
		line-height: 50px;
		font-size: 14px;
		color: #333;
	}

	.interval {
		height: 50px;
		line-height: 50px;
		font-size: 14px;
		color: #333;
	}

	.percentage-num {
		height: 50px;
		line-height: 50px;
		font-size: 14px;
		color: #333;
		margin-left: 20px;
	}

	.el-button-primary {
		cursor: pointer;
		background-color: #FBDB0F;
	}
</style>
